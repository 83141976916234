import React from 'react';

const ScrollWrap = ({ children }) => {
  return (
    <div className="hide-scroll-bar -mx-4 overflow-scroll md:overflow-visible">
      <div
        className="px-4"
        style={{
          minWidth: '768px',
        }}
      >
        {children}
      </div>
    </div>
  );
};

const TableScrollWrap = ({ children }) => {
  return (
    <div className="relative -mb-4 -mr-4 overflow-auto pb-4 md:mr-0">
      <div className="relative w-full min-w-[768px] pr-4">{children}</div>
    </div>
  );
};

const ScrollWrapXl = ({ children }) => {
  return (
    <div className={`hide-scroll-bar -mx-4 overflow-scroll`}>
      <div
        className="px-4"
        style={{
          minWidth: '100%',
        }}
      >
        {children}
      </div>
    </div>
  );
};

const ChainPollTable = ({ theaderOne, theaderTwo, items }) => {
  return (
    <table className="w-full table-fixed border-collapse text-left text-sm lg:text-base">
      <tbody>
        <tr>
          <th className="border-b border-hi-3 py-1 text-sm font-light lg:py-2">
            {theaderOne}
          </th>
          <th className="border-l- border-b border-hi-3 py-1 text-sm font-light lg:py-2">
            {theaderTwo}
          </th>
        </tr>
        {items.map((model, idx, arr) => (
          <tr key={`model-${idx}`} className="text-left">
            <td
              className={` ${idx === arr.length - 1 ? '' : 'border-b'} ${idx === 0 ? 'font-light italic' : ''} border-hi-3 py-1 text-sm font-light lg:py-1.5`}
            >
              <span>{model.name}</span>
            </td>
            <td
              className={` ${idx === arr.length - 1 ? '' : 'border-b'} border-l- border-hi-3 py-1 font-light lg:py-1.5`}
            >
              <div className="flex items-center">
                <div className="w-full">
                  <div
                    className="h-5 bg-hi-60"
                    style={{
                      width: `${model.value * 100}%`,
                      opacity: model.value,
                    }}
                  ></div>
                </div>
                <div>
                  <div className="w-auto whitespace-nowrap">
                    {model.value.toFixed(2)}
                  </div>
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const ChainPollTable2024 = ({ theaderOne, theaderTwo, items }) => {
  return (
    <table className="w-full table-fixed border-[none] text-left text-sm lg:text-base">
      <tbody>
        <tr>
          <th className="w-6/12 border-b border-hi-3 py-1 text-sm font-light lg:w-[20%] lg:py-2">
            {theaderOne}
          </th>
          <th className="w-6/12 border-b border-hi-3 py-1 text-sm font-light lg:w-[80%] lg:py-2">
            {theaderTwo}
          </th>
        </tr>
        {items.map((model, idx, arr) => (
          <tr key={`model-${idx}`} className="text-left">
            <td
              className={`w-[50%] lg:w-[20%] ${idx === arr.length - 1 ? '' : ''} ${idx === 0 ? 'font-light italic' : ''} !border-0 py-1 text-sm font-light lg:py-1.5 ${idx !== arr.length - 1 ? '!border-b' : ''} `}
            >
              <span className="text-normal text-sm font-normal not-italic leading-[175%] text-black">
                {model.name}
              </span>
            </td>
            <td
              className={`w-[50%] lg:w-[80%] ${idx === arr.length - 1 ? '' : ''} !border-0 py-1 font-light lg:py-1.5 ${idx !== arr.length - 1 ? '!border-b' : ''} `}
            >
              <div className="flex items-center">
                <div className="w-full">
                  <div
                    className="h-5 bg-hi-60"
                    style={{
                      width: `${model.value * 100}%`,
                      opacity: model.value,
                    }}
                  ></div>
                </div>
                <div>
                  <div className="text-normal w-auto whitespace-nowrap text-sm font-normal not-italic leading-[175%] text-black">
                    {model.value.toFixed(2)}
                  </div>
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
const DataTable = ({
  theaderOne,
  theaderTwo,
  theaderThree,
  theaderFour,
  items,
}) => {
  return (
    <table className="w-full table-auto table-fixed border-collapse overflow-scroll border border-hi-3 text-left text-sm lg:text-base">
      <tr>
        <th className="border-b border-hi-3 bg-hi-2 px-5 py-4 font-semibold lg:px-7 lg:py-8">
          {theaderOne}
        </th>
        <th className="border-b border-l border-hi-3 bg-hi-2 px-5 py-4 font-semibold lg:px-7 lg:py-8">
          {theaderTwo}
        </th>
        <th className="bg-grey-25 border-b border-l border-hi-3 bg-hi-2 px-5 py-4 font-semibold lg:px-7 lg:py-8">
          {theaderThree}
        </th>
        <th className="bg-grey-25 border-b border-l border-hi-3 bg-hi-2 px-5 py-4 font-semibold lg:px-7 lg:py-8">
          {theaderFour}
        </th>
      </tr>
      {items.map((model, idx, arr) => (
        <tr key={`model-${idx}`} className="text-left">
          <td
            className={` ${idx === arr.length - 1 ? '' : 'border-b'} border-hi-3 bg-hi-2 px-5 py-4 font-light lg:px-7 lg:py-8`}
          >
            {model.title}
          </td>
          <td
            className={` ${idx === arr.length - 1 ? '' : 'border-b'} border-l border-hi-3 bg-hi-2 px-5 py-4 font-light lg:px-7 lg:py-8`}
          >
            <div dangerouslySetInnerHTML={{ __html: model.td1 }} />
          </td>
          <td
            className={` ${idx === arr.length - 1 ? '' : 'border-b'} bg-grey-25 border-l border-hi-3 bg-hi-2 px-5 py-4 font-light lg:px-7 lg:py-8`}
          >
            <div dangerouslySetInnerHTML={{ __html: model.td2 }} />
          </td>
          <td
            className={` ${idx === arr.length - 1 ? '' : 'border-b'} bg-grey-25 border-l border-hi-3 bg-hi-2 px-5 py-4 font-light lg:px-7 lg:py-8`}
          >
            <div dangerouslySetInnerHTML={{ __html: model.td3 }} />
          </td>
        </tr>
      ))}
    </table>
  );
};

export {
  ScrollWrap,
  ChainPollTable,
  ChainPollTable2024,
  ScrollWrapXl,
  DataTable,
  TableScrollWrap,
};
