import { cn } from '@/utils/tailwindUtils';
import React, { useEffect, useRef, useState } from 'react';
import { createNoise3D } from 'simplex-noise';

type Speed = 'slow' | 'normal' | 'fast' | 'very-fast';

const WavyBackground = ({
  children,
  className,
  containerClassName,
  colors,
  waveWidth,
  backgroundFill,
  zoomFactor = 1.1,
  blur = 80,
  speed = 'fast',
  waveOpacity = 0.8,
  animationOffsetTop = 0.6,
  ...props
}: {
  children?: any;
  className?: string;
  containerClassName?: string;
  colors?: string[];
  waveWidth?: number;
  backgroundFill?: string;
  blur?: number;
  speed?: Speed;
  waveOpacity?: number;
  animationOffsetTop?: number;
  [key: string]: any;
}) => {
  const noise = createNoise3D();
  let w: number,
    h: number,
    nt: number,
    i: number,
    x: number,
    ctx: any,
    canvas: any;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const getSpeed = () => {
    switch (speed) {
      case 'slow':
        return 0.001;
      case 'normal':
        return 0.002;
      case 'fast':
        return 0.004;
      case 'very-fast':
        return 0.006;
      default:
        return 0.001;
    }
  };

  const init = () => {
    canvas = canvasRef.current;
    ctx = canvas.getContext('2d');
    w = ctx.canvas.width = window.innerWidth;
    h = ctx.canvas.height = window.innerHeight;
    ctx.filter = `blur(${blur}px)`;
    nt = 0;
    window.onresize = function () {
      w = ctx.canvas.width = window.innerWidth;
      h = ctx.canvas.height = window.innerHeight;
      ctx.filter = `blur(${blur}px)`;
    };
    render();
  };

  const waveColors = colors ?? [
    '#DE00B2',
    '#2F21FA',
    '#4A2FF9',
    '#8370FF',
    '#4E1AEA',
  ];
  const drawWave = (n: number) => {
    nt += getSpeed();
    const scaledWidth = w * zoomFactor;
    const offsetX = (scaledWidth - w) / 2;

    for (i = 0; i < n; i++) {
      ctx.beginPath();
      ctx.lineWidth = waveWidth || 400;
      ctx.strokeStyle = waveColors[i % waveColors.length];
      for (x = -offsetX; x < scaledWidth - offsetX; x += 5) {
        var y = noise(x / 3000, 0.3 * i, nt) * 200;
        ctx.lineTo(x, y + h * (0.4 + i * 0.3));
      }
      ctx.stroke();
      ctx.closePath();
    }
  };

  let animationId: number;
  const render = () => {
    ctx.fillStyle = backgroundFill || 'black';
    ctx.globalAlpha = waveOpacity || 0.5;
    ctx.fillRect((-w * (zoomFactor - 1)) / 2, 0, w * zoomFactor, h);
    drawWave(2);
    animationId = requestAnimationFrame(render);
  };

  useEffect(() => {
    init();
    return () => {
      cancelAnimationFrame(animationId);
    };
  }, []);

  const [isSafari, setIsSafari] = useState(false);
  useEffect(() => {
    // I'm sorry but i have got to support it on safari.
    setIsSafari(
      typeof window !== 'undefined' &&
        navigator.userAgent.includes('Safari') &&
        !navigator.userAgent.includes('Chrome'),
    );
  }, []);

  return (
    <div
      className={cn(
        'relative flex w-full flex-col items-center justify-center lg:w-[57%]',
      )}
    >
      <canvas
        className="absolute inset-0 z-0 h-full w-full"
        ref={canvasRef}
        id="canvas"
        style={{
          ...(isSafari ? { filter: `blur(${blur}px)` } : {}),
        }}
      ></canvas>
      <div className={cn('relative z-10', className)} {...props}>
        {children}
      </div>
    </div>
  );
};

export default WavyBackground;
